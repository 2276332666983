<template>
  <l-default>
    <OTitle>FAQ</OTitle>
    <CAdNewsletterLong />

    <CFAQALL />

    <CAdBitMarketPulse/>
  </l-default>
</template>

<script>
import LDefault from '../../layouts/LDefault'
import OTitle from '../../objects/OTitle'
import CAdNewsletterLong from '@/components/CAdNewsletterLong.vue'
import CFAQALL from '@/components/CFAQALL.vue'
import CAdBitMarketPulse from '@/components/CAdBitMarketPulse.vue'

export default {
  name: 'PageFAQ',
  components: { CAdBitMarketPulse, CFAQALL, CAdNewsletterLong, OTitle, LDefault },
  metaInfo: {
    title: 'Spoof Call And Caller ID Faker | FAQ',
    meta: [
      {
        name: 'description',
        content: 'Spoof Call And Caller ID Faker - FAQ'
      },
      {
        name: 'keywords',
        content: 'myphonerobot faq'
      }
    ]
  }
}
</script>
